import { Box, Button, FileInput, Grid, Heading, Image, Layer, Select, Text, TextArea } from "grommet";
import { Add, Checkbox, CheckboxSelected, Checkmark, Close, Edit, Trash } from "grommet-icons";
import React, { useEffect, useState } from "react";
import configs from '../../../../../../config/config.json';
import { getGestionCollabs } from "../../../../../../services/dashboard";
import { hasAdministrativeRole } from "../../../../../../services/storage_service";
import { toArrayOfArray, toCurrency } from "../../../../../../services/utils";
import { ContactSelectV2 } from '../../../../../contacts/contact_modal/client';
import AddressAutoComplet from "../../../../../elements/address_autocomplete";
import CustomInput, { CustomInputV2 } from "../../../../../elements/custom_input";
import { SmallLine } from "../../../../../elements/line_container";
import LineModification from "../../../../../elements/line_container/line_modification";
import PrimaryButton from "../../../../../elements/primary_button";
import CustomSelect from "../../../../../elements/select_fill";
import { MultipleLineToggleButtons } from "../../../../../elements/toggle_button/toggle_button";
import { getGaranties } from "../../../../configs/service";
import products, { ventCanals } from "../../../../utils/types";
import { getTaux } from "../../config";
import Interlocuteur from "../elements/interlocuter";

const getBase = () => {
    return configs.baseAPI.includes('localhost') || configs.baseAPI.includes('dev') ? "dev." : ""
}

const filteredProducts = selectedCanals => {
    if (!selectedCanals || selectedCanals.length === 0) {
        return products.slice(0, 6);
    }
    let rcPossible = isRCProductPossible(selectedCanals);

    return rcPossible ? products : products.slice(0, 6);
}

const isRCProductPossible = (selectedCanals) => {
    function isLienCanal(canal) {
        return canal === ventCanals[0] || canal === ventCanals[1];
    }
    let onlyLienCanal = false;
    if (selectedCanals.length === 1) {
        onlyLienCanal = isLienCanal(selectedCanals[0]);
    }
    if (selectedCanals.length === 2) {
        onlyLienCanal = isLienCanal(selectedCanals[0]) && isLienCanal(selectedCanals[1]);
    }
    if (selectedCanals.length === 3) {
        onlyLienCanal = isLienCanal(selectedCanals[0]) && isLienCanal(selectedCanals[1]) && isLienCanal(selectedCanals[2]);
    }
    return !onlyLienCanal;
}

const AgenceEdit = ({ agence, onAgenceChange, onSubmit, onCancel, upload, showMessage, editInfo }) => {

    const [commerciales, setCommercials] = useState([]);
    const [desLang, setDesLang] = useState('');
    const [commercial, setCommercial] = useState();
    useEffect(() => {
        getGestionCollabs().then(res => {
            setCommercials(res.data);
            if (!agence.commercial_id) return;
            let selectedCommercial = res.data.find(c => c.id === agence.commercial_id);
            setCommercial(selectedCommercial?.name);
        });
    }, [])

    return (
        <Box
            gap="small"
            width="xlarge"
            alignSelf="center"
            align="center"
            pad="small"
            justify="center"
        >

            <Box justify="center" alignSelf="center">
                <Box align="center">
                    <Heading
                        level={3}
                    >{`Informations de partenaire`}</Heading>
                </Box>

                {hasAdministrativeRole() &&
                    <Box pad={'small'} >
                        <Box gap="xsmall" justify="start" align="start" width={'large'}>
                            <Box style={{ width: '100%' }}>
                                <Text size="xsmall" color={'#707070'}><b>Propriétaire de l'agence</b></Text>
                            </Box>
                            <Box style={{ width: '50%' }}>
                                <CustomSelect
                                    options={commerciales.map(s => s.name)}
                                    value={commercial}
                                    onChange={({ option }) => {
                                        let commercial = commerciales.find(c => c.name === option);
                                        setCommercial(commercial?.name);
                                        onAgenceChange("commercial_id", commercial?.id);
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                }
                <Box pad={'small'} gap="small" width={"large"} >
                    <Text style={{ fontWeight: 'bold' }}>Renseigner informations de l'agence</Text>
                    <hr style={{ width: '100%' }} />
                </Box>
                <Box pad={'small'} gap="small" align="center" width={"large"} >
                    <LineModification text='Raison social' field='raisonSocial'
                        value={agence.raisonSocial}
                        direction='column'
                        onChange={onAgenceChange}
                    />
                    <Box direction="row" gap="small">
                        <Box gap="xsmall" justify="start" align="center" width={'large'}>
                            <Box style={{ width: '100%' }}>
                                <Text size="xsmall" color={'#707070'}>Form juridique</Text>
                            </Box>
                            <Box style={{ width: '100%' }}>
                                <CustomSelect
                                    options={[
                                        'SAS', 'SA', 'EI', 'SARL', 'Site PAP', 'EURL'
                                    ]}
                                    value={agence.formJuridique}
                                    onChange={({ option }) =>
                                        onAgenceChange("formJuridique", option)
                                    }
                                />
                            </Box>
                        </Box>
                        <LineModification text='Siret' field='siret'
                            value={agence.siret}
                            direction='column'
                            onChange={onAgenceChange}
                        />
                    </Box>

                    <AddressAutoComplet
                        contact={agence}
                        updateContact={onAgenceChange}
                        direction='row'
                    />
                    <Box direction="row" gap="small">
                        <LineModification text='Nom de domaine' field='domainName'
                            value={agence.domainName}
                            direction='column'
                            onChange={(field, value) => {
                                if (agence.id && agence.domainName) {
                                    return;
                                }
                                onAgenceChange(field, value);
                            }}
                        />
                        <Box gap="xsmall" justify="start" align="center" width={'large'}>
                            <Box style={{ width: '100%' }}>
                                <Text size="xsmall" color={'#707070'}>Type de société</Text>
                            </Box>
                            <Box style={{ width: '100%' }}>
                                <CustomSelect
                                    options={[
                                        'Agence', 'Conciergerie saisonnière', 'Camping', 'Channel manger', 'Site PAP',
                                        'Hôtel', 'Gîte', 'Propriétaire', 'Réseaux National', 'Startup', 'Réseaux Régionnal'
                                    ]}
                                    value={agence.societeType}
                                    onChange={({ option }) =>
                                        onAgenceChange("societeType", option)
                                    }
                                />
                            </Box>
                        </Box>
                    </Box>


                    <Box direction="row" gap="small">
                        <Box gap="xsmall" justify="start" align="center" width={'large'}>
                            <Box style={{ width: '100%' }}>
                                <Text size="xsmall" color={'#707070'}>Channel manager</Text>
                            </Box>
                            <Box style={{ width: '100%' }}>
                                <CustomSelect
                                    options={[
                                        'BOOKING SYNC', 'INGENIE', 'AVANTIO', 'E-RESERVE', 'D-EDGE', 'ARKIANE', 'SPI', 'ELLOHA', 'SUPERHOTE'
                                    ]}
                                    value={agence.managerChannel}
                                    onChange={({ option }) =>
                                        onAgenceChange("managerChannel", option)
                                    }
                                />
                            </Box>
                        </Box>
                        <Box gap="xsmall" justify="start" align="center" width={'large'}>
                            <Box style={{ width: '100%' }}>
                                <Text size="xsmall" color={'#707070'}>Volume d'affaire</Text>
                            </Box>
                            <Box style={{ width: '100%' }}>
                                <CustomSelect
                                    options={[
                                        'Petit volume', 'Moyen volume', 'Gros volume'
                                    ]}
                                    value={agence.affaireVolume}
                                    onChange={({ option }) =>
                                        onAgenceChange("affaireVolume", option)
                                    }
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box pad={'small'} justify="between" direction='row' gap="medium">
                    <Box direction="row" gap="small" justify="center" align="center" alignSelf="center">
                        <FileInput
                            messages={{
                                browse: "Choisir",
                                dropPrompt: "Logo",
                            }}
                            size={'xsmall'}
                            multiple={false}
                            onChange={(event) => upload('logo', event.target.files)}
                        />
                    </Box>
                    <Box direction="row" gap="small" justify="center" align="center" alignSelf="center">
                        <FileInput
                            messages={{
                                browse: "Choisir",
                                dropPrompt: "Image illustration",
                            }}
                            size={'xsmall'}
                            multiple={false}
                            onChange={(event) => upload('illustration', event.target.files)}
                        />
                    </Box>
                </Box>
                <Box pad={'small'} justify="between" direction='row' gap="medium">
                    <Box direction="row" gap="small" justify="center" align="center" alignSelf="center">
                        <Box width={'small'} direction='row' gap="small">
                            {agence.logo && <Image src={agence.logo} />}
                            {agence.logo && <Trash color="brand" style={{ cursor: 'pointer' }} onClick={() => onAgenceChange('logo', null)} />}
                        </Box>
                    </Box>
                    <Box direction="row" gap="small" justify="center" align="center" alignSelf="center">
                        <Box width={'small'} gap='small' direction="row">
                            {agence.illustration && <Image src={agence.illustration} />}
                            {agence.illustration && <Trash color="brand" style={{ cursor: 'pointer' }} onClick={() => onAgenceChange('illustration', null)} />}
                        </Box>
                    </Box>
                </Box>
                <Box pad={'small'} justify="between" direction='row' gap="medium">
                    <Box gap="xsmall" justify="start" align="center" width={'large'}>
                        <Box style={{ width: '100%' }} direction='row' gap="medium" align="center">
                            <Text size="xsmall" color={'#707070'}>Description du site</Text>
                            <PrimaryButton label={'🇫🇷 Fr'} background={desLang === '' ? '#E82C70' : '#fff'} color={desLang === '' ? '#fff' : '#000'}
                                onClick={() => setDesLang('')}
                            />
                            <PrimaryButton label={'🇺🇸 En'} background={desLang === '_en' ? '#E82C70' : '#fff'} color={desLang === '_en' ? '#fff' : '#000'}
                                onClick={() => setDesLang('_en')}
                            />

                        </Box>
                        <Box style={{ width: '100%', height: 300 }}>
                            <TextArea value={agence[`descriptionSite${desLang}`]}
                                fill
                                size="xsmall"
                                onChange={event => onAgenceChange(`descriptionSite${desLang}`, event.target.value)}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box pad={'small'} gap="small" width={"large"} >
                <Text style={{ fontWeight: 'bold' }}>Renseigner les informations de l’interlocuteur</Text>
                <hr style={{ width: '100%' }} />

                <Interlocuteurs interlocuteurs={agence.interlocuteurs} onAgenceChange={onAgenceChange} showMessage={showMessage} />
            </Box>
            <Box pad={'small'} gap="small" width={"large"} >
                <Text style={{ fontWeight: 'bold' }}>Espace partenaire</Text>
                <hr style={{ width: '100%' }} />

                <Box gap='xxsmall' align='center' width={'full'}>
                    <Box direction="row" gap="small">
                        <LineModification text='Email' field='user_email'
                            value={agence.user_email}
                            direction='column'
                            onChange={(field, value) => onAgenceChange(field, value)}
                        />
                        <LineModification text='Mot de passe temporaire' field='init_password'
                            value={agence.init_password}
                            direction='column'
                            disabled={true}
                        />
                    </Box>
                </Box>
                <Box>
                    <CanalTitle text="Espace Partenaire :" />
                    <Text size="small">
                        <a target={'_blank'} href={`https://${getBase()}partenaire-voyage.trustiway.com`}>
                            {`https://${getBase()}partenaire-voyage.trustiway.com`}
                        </a>
                    </Text>
                </Box>
            </Box>
            {!editInfo &&
                <>
                    <Box pad={'small'} gap="small" width={"large"} >
                        <Text style={{ fontWeight: 'bold' }}>Canal de vente</Text>
                        <hr style={{ width: '100%' }} />

                        <Text size="small">Méthode de vente :</Text>
                        <MultipleLineToggleButtons
                            size={3}
                            groups={ventCanals.map(value => ({
                                label: value,
                                selected: (agence.ventCanals || []).includes(value),
                                onClick: () => {
                                    if (value === 'Booking Sync') return;
                                    let ventCanals = agence.ventCanals || [];
                                    if (ventCanals.includes(value)) {
                                        ventCanals = ventCanals.filter(v => v !== value);
                                    } else {
                                        ventCanals.push(value);
                                    }
                                    if (!isRCProductPossible(ventCanals)) {
                                        onAgenceChange('products', agence.products?.filter(p => p.product !== products[6] && p.product !== products[7]));
                                    }
                                    onAgenceChange('ventCanals', ventCanals);
                                },
                            }))}
                        />
                    </Box>
                    <Box pad={'small'} gap="small" width={"large"} >
                        <Text style={{ fontWeight: 'bold' }}>Produits disponibles</Text>
                        <hr style={{ width: '100%' }} />
                        <MultipleLineToggleButtons
                            groups={filteredProducts(agence.ventCanals).map(value => ({
                                label: value,
                                selected: (agence.products || []).map(p => p.product).includes(value),
                                onClick: () => {
                                    if (value === 'Booking Sync') return;
                                    let prods = agence.products || [];
                                    if (prods.map(p => p.product).includes(value)) {
                                        prods = prods.filter(p => p.product !== value);
                                    } else {
                                        prods.push(getTaux(value, agence.classicBaseTaux, agence.rcBaseTaux, agence.rcLuxTaux));
                                    }
                                    onAgenceChange('products', prods.map(s => s));
                                },
                            }))}
                        />
                        <Box gap="xsmall" >
                            <Text size="small">Taux base:</Text>
                            <Box direction="row" gap="small" >
                                <Box width={'small'}>
                                    <CustomInput value={agence.classicBaseTaux} onChange={event => {
                                        let value = event.target.value || '';
                                        onAgenceChange('classicBaseTaux', value.replace(',', '.'));
                                        let classicBaseTaux = value ? parseFloat(value.replace(',', '.')) : undefined;
                                        let prods = agence.products?.map(p => getTaux(p.product, classicBaseTaux || p.taux, agence.rcBaseTaux, agence.rcLuxTaux));
                                        onAgenceChange('products', prods);
                                    }}
                                        placeholder={'%'}
                                        size='xsmall'
                                    />
                                </Box>
                                <Box justify="center">
                                    <Text size="xsmall" color={'#FF0000'}>Ce champs ne doit pas être inférieur à 2,8%</Text>
                                </Box>
                            </Box>
                        </Box>
                        {agence.products?.map(p => p.product).includes(products[6]) &&
                            <Box gap="xsmall" >
                                <Text size="small">Taux RC :</Text>
                                <Box direction="row" gap="small" >
                                    <Box width={'small'}>
                                        <CustomInput value={agence.rcBaseTaux} onChange={event => {
                                            let value = event.target.value || '';
                                            onAgenceChange('rcBaseTaux', value.replace(',', '.'));
                                            let rcBaseTaux = value ? parseFloat(value.replace(',', '.')) : undefined;
                                            let prods = agence.products?.map(p => getTaux(p.product, agence.classicBaseTaux || p.taux, rcBaseTaux, agence.rcLuxTaux));
                                            onAgenceChange('products', prods);

                                        }}
                                            size='xsmall'
                                            placeholder={'%'}
                                        />
                                    </Box>
                                    <Box justify="center">
                                        <Text size="xsmall" color={'#FF0000'}>Ce champs ne doit pas être inférieur à 1,5%</Text>
                                    </Box>
                                </Box>
                            </Box>
                        }
                        {agence.products?.map(p => p.product).includes(products[7]) &&
                            <Box gap="xsmall" >
                                <Text size="small">Taux RC Luxe:</Text>
                                <Box direction="row" gap="small" >
                                    <Box width={'small'}>
                                        <CustomInput value={agence.rcLuxTaux} onChange={event => {
                                            let value = event.target.value || '';
                                            onAgenceChange('rcLuxTaux', value.replace(',', '.'));
                                            let rcLuxTaux = value ? parseFloat(value.replace(',', '.')) : undefined;
                                            let prods = agence.products?.map(p => getTaux(p.product, agence.classicBaseTaux || p.taux, agence.rcBaseTaux, rcLuxTaux));
                                            onAgenceChange('products', prods);

                                        }}
                                            size='xsmall'
                                            placeholder={'%'}
                                        />
                                    </Box>
                                    <Box justify="center">
                                        <Text size="xsmall" color={'#FF0000'}>Ce champs ne doit pas être inférieur à 1,8%</Text>
                                    </Box>
                                </Box>
                            </Box>
                        }
                        <SmallLine leftLabel={'Taux package voyage'} bold={true}
                            rightChildren={<Text size="xsmall" style={{ fontWeight: 'bold' }}>Retrocession : </Text>}
                        />
                        {
                            (agence.products || []).map((p, idx) => (
                                <SmallLine key={idx}
                                    align="start"
                                    leftLabel={`${p.product} ::(${p.taux_displayed} %)::`}
                                    rightChildren={
                                        <ProductConfig
                                            p={p}
                                            onAgenceChange={onAgenceChange}
                                            agence={agence}
                                        />
                                    }
                                />
                            ))
                        }
                    </Box>
                </>
            }
            <Box direction="row" align="center" justify="center" gap="medium" pad={'medium'}>
                <PrimaryButton
                    label={`Valider`}
                    icon={<Checkmark />}
                    background="#59A4F4"
                    onClick={onSubmit}
                />
                <PrimaryButton
                    label={`Annuler`}
                    icon={<Close />}
                    background="#6c757d"
                    onClick={() => onCancel()}
                />
            </Box>

        </Box>
    );
};



function ProductConfig({ p, onAgenceChange, agence }) {
    const [show, setShow] = useState(false);
    const [option, setOption] = useState(false);

    return <Box gap="xsmall">
        <Box direction="row" gap="small" justify="center" align="center">
            <CustomInput placeholder={'%'} value={p.taux_retro_agence}
                onChange={e => {
                    p.taux_retro_agence = e.target.value.replace(',', '.');
                    onAgenceChange('products', agence.products.map(p => p));
                }}
                size='xsmall' />
            <Box><Text size="xsmall">Max {p.max_retro}%</Text></Box>
        </Box>
        <Box gap="xxsmall">
            <Box direction="row" gap="small" align="center" >
                <Text size='xsmall'><strong>Garantie:</strong></Text>
                <Edit size="small" color="brand" style={{ cursor: 'pointer' }} onClick={() => setShow(true)} />
            </Box>
            {p.garantie_principal && <Box direction="row" gap="medium" align="center" >
                <Text size="small">{p.garantie_principal.compagny}</Text>
                <Box >
                    {p.garantie_principal.garanties?.map((gar, index) => {
                        return (
                            <>
                                <Text key={index} size={"xsmall"}>
                                    {index + 1}. {gar.name} - {toCurrency(gar.plafond)} - {toCurrency(gar.franchise)}
                                </Text>
                            </>
                        );
                    })}
                </Box>
            </Box>}

        </Box>
        <Box gap="xxsmall">
            <Box direction="row" gap="small" align="center" >
                <Text size='xsmall'><strong>Option à la vente:</strong></Text>
                <Edit size="small" color="brand" style={{ cursor: 'pointer' }} onClick={() => setOption(true)} />
            </Box>
            {p.garanties_option?.length && <Box gap="xsmall" >
                {p.garanties_option?.map((option, index) => (
                    <Text key={index} size={"xsmall"}>
                        {index + 1}. {option.name} - {toCurrency(option.plafond)} - {toCurrency(option.franchise)}
                    </Text>))}
            </Box>}
        </Box>
        <Box gap="xxsmall">
            <Box direction="row" gap="small" align="center" >
                <Text size='xsmall'><strong>Option à la vente:</strong></Text>
                {p.garanties_parametres?.length &&
                    <CheckboxSelected size="small" color="brand" style={{ cursor: 'pointer' }}
                        onClick={() => {
                            p.garanties_parametres = null;
                            onAgenceChange('products', agence.products.map(p => p));
                        }}
                    />}
                {!p.garanties_parametres?.length &&
                    <Checkbox size="small" color="brand" style={{ cursor: 'pointer' }}
                        onClick={() => {
                            p.garanties_parametres = [{
                                "name": "Perte et vol de clés",
                                "plafond": 400,
                                franchise: 20
                            }];
                            onAgenceChange('products', agence.products.map(p => p));
                        }}
                    />}

            </Box>
            {p.garanties_parametres?.length && <Box gap="xsmall" >
                {p.garanties_parametres?.map((option, index) => (
                    <Text key={index} size={"xsmall"}>
                        {index + 1}. {option.name} - {toCurrency(option.plafond)} - {toCurrency(option.franchise)}
                    </Text>))}
            </Box>}
        </Box>
        {show && <SelectGaranties
            produit={p.product}
            onClose={() => setShow(false)}
            onGarantiesSelected={garantie => {
                p.garantie_principal = garantie;
                onAgenceChange('products', agence.products.map(p => p));
                setShow(false);
            }}
        />
        }
        {option && <SelectOptionVente
            onClose={() => setOption(false)}
            product={p}
            onGarantiesValidated={garantie => {
                p.garanties_option = garantie;
                onAgenceChange('products', agence.products.map(p => p));
                setOption(false);
            }}
        />}
    </Box>;
}


const CanalTitle = ({ text }) => (
    <Text size="small"
        style={{ textDecoration: 'underline', fontWeight: 'bold', color: '#707070' }}
    >
        {text}
    </Text>
)

const Interlocuteurs = ({ interlocuteurs = [], onAgenceChange, showMessage }) => {
    const [interlocuteur, setInterlocuteur] = React.useState({});

    const onIterChange = (field, value) => {
        setInterlocuteur(Object.assign({}, value));
    }

    const addInterlocuteur = () => {
        console.log(interlocuteur)
        if (
            !interlocuteur.firstname ||
            !interlocuteur.lastname ||
            !interlocuteur.phone ||
            !interlocuteur.email
        ) {
            showMessage('Information d\'interlocuteur n\'est pas complet', 'error');
            return;
        }
        interlocuteurs.push(interlocuteur);
        setInterlocuteur({});
        onAgenceChange('interlocuteurs', interlocuteurs.map(s => s));
    }

    const removeInterlocuteur = idx => {
        interlocuteurs.splice(idx, 1)
        onAgenceChange('interlocuteurs', interlocuteurs);
    }

    const onReceivingMailChange = idx => {
        let iter = interlocuteurs[idx];
        iter.receiving_mail = iter.receiving_mail ? false : true;
        onAgenceChange('interlocuteurs', interlocuteurs.map(i => i));
    }

    return (
        <Box pad={'small'} gap="small" width={"large"} >
            <Box pad={'small'} gap="small" width={"large"}>
                {
                    toArrayOfArray(interlocuteurs).map((interG, index) => (
                        <Box key={index} pad={'small'} gap="large" width={"large"} direction="row" >
                            {
                                interG.map((iter, idx) => (
                                    <Interlocuteur key={idx} idx={idx + index * 3} iter={iter} remove={() => removeInterlocuteur(idx + index * 3)}
                                        onReceivingMailChange={() => onReceivingMailChange(idx + index * 3)}
                                    />
                                ))
                            }
                        </Box>
                    ))
                }
            </Box>

            <ContactSelectV2
                contact={interlocuteur}
                updateContact={onIterChange}
                addressIncluded={false}
                title='Interlocuteur'
                size="xsmall"
                isPro={true}
            />
            <Box gap="small" width={"medium"} direction="row" align="center" justify="center">
                <PrimaryButton icon={<Add />}
                    onClick={addInterlocuteur}
                    label="Ajouter un interlocuteur"
                />
            </Box>
        </Box>
    )
}

const SelectGaranties = ({ onClose, produit, onGarantiesSelected }) => {
    const [garanties, setGaranties] = useState([]);
    useEffect(() => {
        getGaranties(0, { produit }).then(res => {
            setGaranties(res.data);
        });
    }, []);
    return (
        <Layer onClickOutside={onClose}>
            <Box width={'medium'} pad={'small'} gap="small">
                <Select
                    size="small"
                    placeholder="Recherche des garanties"
                    labelKey={"label"}
                    valueKey={{ key: 'id', reduce: true }}
                    options={garanties}
                    onChange={({ option }) => {
                        onGarantiesSelected(option);
                    }}
                >
                    {(option, index) => (<Garantie key={index + 1} garantie={option} />)}
                </Select>
            </Box>
        </Layer>
    )
}

const Garantie = React.memo(({ garantie }) => (
    <Box direction="row" gap="small" align="center" pad="small">
        <Grid columns={["30%", "70%"]} fill justify="center" align="center">
            <Text size={"small"}><strong>{garantie.compagny} </strong></Text>
            <Box>
                {garantie?.garanties?.map((gar, index) => {
                    return (
                        <>
                            <Text key={index} size={"small"}>
                                {index + 1}. {gar.name} - {toCurrency(gar.plafond)} - {toCurrency(gar.franchise)}
                            </Text>
                        </>
                    )
                })}
            </Box>

        </Grid>
    </Box>
));

const SelectOptionVente = ({ onClose, product, onGarantiesValidated }) => {
    const [values, setValues] = useState([]);
    useEffect(() => {
        if (product.garanties_option) {
            setValues(product.garanties_option);
        }
    }, [product]);
    return (
        <Layer onClickOutside={onClose}>
            <Box width={'large'} pad={'medium'} gap="small">
                <Heading level={4} >Garanties option à la vente</Heading>
                {values?.map((garantie, index) => {
                    return (
                        <Box key={index} gap="small" direction='row'>
                            <LineColumn
                                leftLabel={"Nom"}
                                rightChildren={
                                    <CustomInputV2
                                        size='small'
                                        value={garantie.name}
                                        onChange={(e) => {
                                            values[index].name = e.target.value;
                                            setValues([...values]);
                                        }} />
                                }
                            />
                            <LineColumn
                                leftLabel={"Plafond"}
                                rightChildren={
                                    <CustomInputV2
                                        size='small'
                                        value={garantie.plafond}
                                        onChange={(e) => {
                                            values[index].plafond = e.target.value;
                                            setValues([...values]);
                                        }} />
                                }
                            />
                            <LineColumn
                                leftLabel={"Franchise"}
                                rightChildren={
                                    <CustomInputV2
                                        size='small'
                                        value={garantie.franchise}
                                        onChange={(e) => {
                                            values[index].franchise = e.target.value;
                                            setValues([...values]);
                                        }} />
                                }
                            />
                        </Box>
                    )
                })}
                <Box direction='row' gap='small'
                    onClick={() =>
                        setValues([
                            ...values,
                            { name: "", plafond: 0, franchise: 0 }
                        ])
                    }

                >
                    <Add
                        color='brand'
                    />
                    <Text size="small">Ajouter une garantie</Text>
                </Box>
                <Box direction='row' gap='small' justify='end'>
                    <Button label="Enregistrer" primary onClick={() => onGarantiesValidated(values)} />
                    <Button label="Annuler" onClick={onClose} />
                </Box>
            </Box>
        </Layer>
    )
}

const LineColumn = ({ leftLabel, rightChildren }) => (
    <Box gap="xxsmall" style={{ width: '100%' }} >
        <Text size="small" >{leftLabel} :</Text>
        {rightChildren}
    </Box>
)


export default AgenceEdit;

