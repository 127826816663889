import { navigate } from "gatsby-link";
import {
  Box,
  Image,
  Menu, ResponsiveContext, Tab,
  Tabs,
  Text
} from "grommet";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminCommissionReport from "../components/admin/admin_commission_reports";
import GestionCommission from "../components/admin/gestion_com";
import GestionStats from "../components/admin/gestion_stats";
import GestionApporteurPro from "../components/apporteur_pro";
import MyContacts from "../components/contacts/my_contacts";
import Dashboard from "../components/dashboard/";
import ReportsClosed from "../components/dashboard/reports_closed";
import Layout from "../components/elements/layout";
import Appels from "../components/stats/appels";
import MesCommissions from "../components/stats/commissions";
import ObjectifCA from "../components/stats/objectif_ca";
import ObjectifContrats from "../components/stats/objectif_contrat";
import TrustiBot from "../components/trustibot";
import AssuranceDashboard from "../components/ventes/assurance_dashboard";
import VenteEpargnes from "../components/ventes/epargnes";
import VenteParticuliers from "../components/ventes/particuliers";
import VenteProfessionels from "../components/ventes/professionels";
import Voyages from "../components/voyages";
import mainLogo from "../images/logo.png";
import userlogo from "../images/user.png";
import { getGestionCollabs, getUsers } from "../services/dashboard";
import { logout } from "../services/login_service";
import storageService, { clearContextBot, hasAdministrativeRole, hasAdminRole, hasClassiqueManagerRole, hasComptableRole, hasOnlyAdministrativeRole, hasRightToViewVoyage } from "../services/storage_service";
import MyTrustiwayPanel from "../components/my_trustiway";
import MainMenu from "../components/elements/menu/menu";
import Credits from "../components/ventes/credit/credit";
import BiliothequeCompagnie from "../components/outils/bibliotheque_compagnies";
import ObjectifDay from "../components/stats/objectif_day";
import AppUsers from "../components/my_apporteurs";
import Prevoyances from "../components/professionels/prevoyances";
import { fetchUser } from "../components/admin/service";
import ObjectifCADay from "../components/stats/objectif_ca_day";
import DashboardCredit from "../components/dashboard_credit";
import GestionOpportunity from "../components/admin/gestion_opportunity";
import AssuranceVies from "../components/ventes/asurrance-vies";



const MENU = {
  trustibot: {
    trustibot: 'trustibot'
  },
  dashboard: {
    assurance: 'dashboard_assurance',
    credit: 'dashboard_crédit',
    epargne: "dashboard_epargne",
    prevoyance: 'dashboard_prevoyance',
    mes_contacts: 'dashboard_mes-contacts',
    mes_contrats: 'dashboard_mes-contrats',
    immobilier: 'immobilier-contrats',
  },
  stats: {
    objectif_ca: 'stats_objectif_ca',
    objectif_contrats: 'stats_objectif_contrats',
    objectif_ca_day: 'stats_objectif_ca_day',
    objectif_day: 'stats_objectif_day',
    stats_appel: 'stats_appels',
    commissions: 'stats_commissions'
  },
  apporteurs: {
    apporteurs: 'apporteurs',
    app_apporteurs: 'app_apporteurs'
  },
  vents: {
    assurance_dashboard: 'assurance_dashboard',
    particuliers: 'ventes_particuliers',
    professionel: 'ventes_professionels',
    immobilier: 'immobilier',
    credit: 'ventes_crédit',
    assurance_vie: 'assurance_vie',
    epargne: 'ventes_epargne',
    prevoyance: 'ventes_prevoyance',
    voyage: 'ventes_voyage',
    apporteur: 'ventes_apporteur',
    credit_all: 'credit_all',
    credit_regroup_projet: 'credit_regroup_projet',
    credit_regroup: 'credit_regroup',
  },
  boit_outils: {
    boit_outils: 'boit_outils'
  },
  admin: {
    commissions_tw: 'admin_commissions_tw',
    gest_commerciaux: 'admin_gestion_commerciaux',
    gest_stats: 'admin_gestion_stats',
    gest_opportunity: 'admin_gestion_opportunity'
  },
  my_trustiway: {
    dashboard: 'my_trustiway_dashboard',
    utilisateurs: 'my_trustiway_utilisateurs',
    partenaire: 'my_trustiway_partenaires',
    comptabilité: 'my_trustiway_comptabilité',
    taux_commissions: 'my_trustiway_taux_commissions',
  }
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      menuOpen: false,
      user: {},
      active_menu: MENU.trustibot.trustibot, //MENU.dashboard.assurance,
      commerciaux: [],
      all_employees: []
    };
  }
  componentDidMount() {
    const token = storageService.getToken();
    if (!token) {
      navigate("/login");
      return;
    }
    this.setState({ token });
    this.refreshCurrentUser();

    getGestionCollabs()
      .then(res => {
        if (res.data.length === 0) return;
        this.setState({ commerciaux: [{ name: 'Tous', id: 'All' }, ...res.data] })
      }).catch(e => {
        console.log("error = ", e);
      });
    getUsers()
      .then(res => this.setState({ all_employees: res.data })).catch(e => {
        console.log("error = ", e);
      });

    clearContextBot();
  }

  refreshCurrentUser = async () => {
    const user = storageService.getUser();
    const res = await fetchUser(user.id);
    storageService.refreshUser(res.data);
    this.setState({ user: res.data });
  }

  showMessage = (message, type = 'info') => {
    console.log(type);
    toast[type](message);
  };

  showMenu = () => {
    this.setState({ menuOpen: true });
  };

  logout = () => {
    logout();
    navigate("/login");
  };

  getSubMenu = () => {
    switch (this.state.active_menu) {
      case MENU.dashboard.assurance:
        return <Dashboard showMessage={this.showMessage} />;
      case MENU.dashboard.credit:
        return (
          <DashboardCredit showMessage={this.showMessage} />
        );
      case MENU.dashboard.epargne:
        return (
          <EmptyContent label={'epargne'} />
        )
      case MENU.dashboard.mes_contrats:
        return (
          <ReportsClosed
            commerciaux={this.state.commerciaux}
            showMessage={this.showMessage}
            indicator={true}
            personal={(hasAdministrativeRole()) ? false : true}
          />
        )
      case MENU.dashboard.mes_contacts:
        return (
          <MyContacts showMessage={this.showMessage} />
        )

      // STATS MENU
      case MENU.stats.objectif_contrats:
        return (
          <ObjectifContrats showMessage={this.showMessage} />
        )
      case MENU.stats.objectif_ca:
        return (
          <ObjectifCA showMessage={this.showMessage} />
        )
      case MENU.stats.objectif_ca_day:
        return (
          <ObjectifCADay showMessage={this.showMessage} />
        )
      case MENU.stats.commissions:
        return (
          <MesCommissions showMessage={this.showMessage} />
        )
      case MENU.stats.stats_appel:
        return (
          <Appels showMessage={this.showMessage} />
        )
      case MENU.stats.objectif_day:
        return (
          <ObjectifDay showMessage={this.showMessage} />
        )

      // VENTE MENU
      case MENU.vents.voyage:
        return (
          <Voyages showMessage={this.showMessage} />
        )
      case MENU.vents.assurance_dashboard:
        return (
          <AssuranceDashboard showMessage={this.showMessage}
            commerciaux={this.state.commerciaux} />
        );
      case MENU.vents.particuliers:
        return (
          <VenteParticuliers showMessage={this.showMessage}
            commerciaux={this.state.all_employees} />
        );
      case MENU.vents.epargne:
        return (
          <VenteEpargnes showMessage={this.showMessage}
            commerciaux={this.state.all_employees}
          />
        );
      case MENU.vents.prevoyance:
        return (
          <Prevoyances
            showMessage={this.showMessage}
            commerciaux={this.state.commerciaux}
            title={"Prévoyance"}
          />
        )

      case MENU.vents.apporteur:
      case MENU.vents.credit:
      case MENU.vents.credit_all:
        return (<Credits
          key={"credit_all"}
          showMessage={this.showMessage}
          commerciaux={this.state.all_employees}></Credits>);
      case MENU.vents.credit_regroup:
        return (<Credits
          key={"credit_regroup"}
          showMessage={this.showMessage}
          sub_product_detail="Regroupement de crédits"
          commerciaux={this.state.all_employees}></Credits>);
      case MENU.vents.credit_regroup_projet:
        return (<Credits
          key={"credit_regroup_projet"}
          showMessage={this.showMessage}
          sub_product_detail="Regroupement de crédits projet"
          commerciaux={this.state.all_employees}></Credits>);
      case MENU.vents.assurance_vie:
        return (<AssuranceVies
          showMessage={this.showMessage}
          commerciaux={this.state.all_employees}></AssuranceVies>);
      case MENU.vents.professionel:
        return (
          <VenteProfessionels showMessage={this.showMessage}
            commerciaux={this.state.all_employees}
          />
        );

      // APPORTEURS
      case MENU.apporteurs.apporteurs:
      case MENU.apporteurs.souscriptions:
        return (
          <GestionApporteurPro
            showMessage={this.showMessage}
          />
        );
      case MENU.apporteurs.app_apporteurs:
        return (
          <AppUsers
            showMessage={this.showMessage}
          />
        )

      case MENU.boit_outils.boit_outils:
        return (
          <Box flex style={{ paddingTop: 20 }} direction="row">
            <Tabs>
              <Tab title="Bibliothèque Compagnie">
                <Box flex justify="center" alignItems="center" >
                  <Text color={"red"} alignSelf="center">TrustiBibli24-%</Text>
                  <BiliothequeCompagnie
                    embedded={true}
                    showMessage={this.showMessage}
                  />
                </Box>
              </Tab>
            </Tabs>
          </Box>
        );
      case MENU.admin.commissions_tw:
        return (
          <AdminCommissionReport showMessage={this.showMessage}
            commerciaux={this.state.commerciaux}
          />
        )
      case MENU.admin.gest_commerciaux:
        return (
          <GestionCommission showMessage={this.showMessage} />
        )
      case MENU.admin.gest_stats:
        return (
          <GestionStats showMessage={this.showMessage} />
        )

      case MENU.admin.gest_opportunity:
        return (
          <GestionOpportunity showMessage={this.showMessage} />
        )
      case MENU.my_trustiway.dashboard:
        return (
          <MyTrustiwayPanel selectedTab={0} showMessage={this.showMessage} />)
      // case MENU.my_trustiway.utilisateurs:
      // 	return (
      // 		<MyTrustiwayPanel selectedTab={1} showMessage={this.showMessage}  />)
      // case MENU.my_trustiway.partenaire:
      // 	return (
      // 		<MyTrustiwayPanel selectedTab={2} showMessage={this.showMessage}  />)
      // case MENU.my_trustiway.comptabilité:
      // 	return (
      // 		<MyTrustiwayPanel selectedTab={3} showMessage={this.showMessage} />)
      case MENU.trustibot.trustibot:
        clearContextBot();
        return (<TrustiBot fullscreen={true}></TrustiBot>);
      default:
        return <Box />
    }
  }

  to = (menu) => this.setState({ active_menu: menu });

  render() {
    return (
      <Layout style={{ justifyContent: "center", alignContent: "center" }}>
        <ResponsiveContext.Consumer>
          {size => (
            <>
              <Box
                as="main"
                flex
                overflow="auto"
                style={{ width: "100%" }}
                direction="column"
                alignContent="center"
                alignSelf="center"
              >
                <Box
                  style={{ width: "100%", height: size === "small" ? 120 : 80 }}
                  alignContent="center"
                  background={'#F0F0F0'}
                  alignSelf="center"
                >
                  {size !== "small" && <Box style={{ position: 'absolute', left: 20, height: size === "small" ? 120 : 80 }} justify="center">
                    <Image onClick={() => this.to(MENU.trustibot.trustibot)} src={mainLogo} width={250} />
                  </Box>}
                  <Box align="center"
                    style={{ position: 'absolute', right: 10, height: size === "small" ? 120 : 80 }}
                    justify="center" gap="small">
                    <Menu
                      dropProps={{
                        align: { top: 'bottom', left: 'left' },
                      }}
                      items={[{ label: <Text size="small" color={'#707070'}>Logout</Text>, onClick: this.logout }]}
                    >
                      <Box direction="row" align="center"
                        style={{ height: size === "small" ? 120 : 80 }}
                        justify="center" gap="small">
                        {size !== "small" && <Text size="small" textAlign="end" color={'#707070'}>
                          Bonjour {this?.state?.user?.prenom}
                        </Text>}
                        <Box><Image src={userlogo} /></Box>

                      </Box>
                    </Menu>
                  </Box>
                  <Box justify="center" align="center" style={{ height: size === "small" ? 120 : 80 }}>
                    <Box direction="row" gap="large" align="center" justify="center">
                      <Box direction="row" gap="medium" justify="center" align="center" wrap pad={size === "small" ? "0 30px 0 10px" : "0 130px 0 240px"}>
                        <MainMenu
                          isActive={this.state.active_menu.startsWith('trustibot')}
                          label={'Trustibot'}
                          onClick={() => this.to(MENU.trustibot.trustibot)}
                        />
                        <MainMenu
                          isActive={this.state.active_menu.startsWith('dashboard')}
                          label={'Dashboard'}
                          items={[
                            {
                              label: <Text size="small" color={'#707070'}>Assurance</Text>,
                              onClick: () => this.to(MENU.dashboard.assurance),
                            },
                            {
                              label: <Text size="small" color={'#707070'}>Crédit</Text>,
                              onClick: () => this.to(MENU.dashboard.credit)
                            },
                            {
                              label: <Text size="small" color={'#707070'}>Epargne</Text>,
                              onClick: () => this.to(MENU.dashboard.epargne)
                            },
                            {
                              label: <Text size="small" color={'#707070'}>Prévoyance</Text>,
                              onClick: () => this.to(MENU.dashboard.prevoyance)
                            },
                            {
                              label: <Text size="small" color={'#707070'}>Immobilier</Text>,
                              onClick: () => this.to(MENU.dashboard.immobilier)
                            },
                            {
                              label: <Text size="small" color={'#707070'}>Mes contrats</Text>,
                              onClick: () => this.to(MENU.dashboard.mes_contrats)
                            },
                            {
                              label: <Text size="small" color={'#707070'}>Mes contacts</Text>,
                              onClick: () => this.to(MENU.dashboard.mes_contacts)
                            },
                          ]}
                        />
                        {!hasOnlyAdministrativeRole() &&
                          <MainMenu
                            isActive={this.state.active_menu.startsWith('stats')}
                            label={'Stats'}
                            items={[
                              {
                                label: <Text size="small" color={'#707070'}>Objectif CA</Text>,
                                onClick: () => this.to(MENU.stats.objectif_ca),
                              },
                              {
                                label: <Text size="small" color={'#707070'}>Objectif Contrats</Text>,
                                onClick: () => this.to(MENU.stats.objectif_contrats),
                              },
                              {
                                label: <Text size="small" color={'#707070'}>Objectif CA Day</Text>,
                                onClick: () => this.to(MENU.stats.objectif_ca_day),
                              },
                              {
                                label: <Text size="small" color={'#707070'}>Objectif Day</Text>,
                                onClick: () => this.to(MENU.stats.objectif_day),
                              },
                              {
                                label: <Text size="small" color={'#707070'}>Stats appels</Text>,
                                onClick: () => this.to(MENU.stats.stats_appel),
                              },
                              {
                                label: <Text size="small" color={'#707070'}>Commissions</Text>,
                                onClick: () => this.to(MENU.stats.commissions),
                              },
                            ]}
                          />
                        }
                        <MainMenu
                          isActive={this.state.active_menu.startsWith('ventes')}
                          label={'Ventes'}
                          items={[
                            {
                              label: <Text size="small" color={'#707070'}>Assurance</Text>,
                              onClick: () => this.to(MENU.vents.assurance_dashboard)
                            },
                            {
                              label: <Text size="small" color={'#707070'}>Crédit</Text>,
                              onClick: () => this.to(MENU.vents.credit),
                            },
                            {
                              label: <Text size="small" color={'#707070'}>Assurance de vie</Text>,
                              onClick: () => this.to(MENU.vents.assurance_vie),
                            },
                            {
                              label: <Text size="small" color={'#707070'}>Epargne</Text>,
                              onClick: () => this.to(MENU.vents.epargne),
                            },
                            {
                              label: <Text size="small" color={'#707070'}>Prévoyance</Text>,
                              onClick: () => this.to(MENU.vents.prevoyance),
                            },
                            {
                              label: <Text size="small" color={'#707070'}>Immobilier</Text>,
                              onClick: () => this.to(MENU.vents.immobilier),
                            },
                          ].concat(
                            hasRightToViewVoyage() ?
                              [
                                {
                                  label: <Text size="small" color={'#707070'}>Voyage</Text>,
                                  onClick: () => this.to(MENU.vents.voyage),
                                },
                                {
                                  label: <Text size="small" color={'#707070'}>Apporteur</Text>,
                                  onClick: () => this.to(MENU.vents.apporteur),
                                },
                              ] :
                              [
                                {
                                  label: <Text size="small" color={'#707070'}>Apporteur</Text>,
                                  onClick: () => this.to(MENU.vents.apporteur),
                                },
                              ]
                          )}
                        />
                        <MainMenu
                          isActive={this.state.active_menu.startsWith('app_apporteurs')}
                          label={'Mes Apporteurs APP'}
                          items={[]}
                          onClick={() => this.to(MENU.apporteurs.app_apporteurs)}
                        />
                        {(hasClassiqueManagerRole() || hasAdminRole()) &&
                          <MainMenu
                            isActive={this.state.active_menu.startsWith('apporteurs')}
                            label={'Apporteurs'}
                            items={[]}
                            onClick={() => this.to(MENU.apporteurs.apporteurs)}
                          />
                        }
                        <MainMenu
                          isActive={this.state.active_menu.startsWith('boit_outils')}
                          label={'Boite à outils'}
                          items={[]}
                          onClick={() => this.to(MENU.boit_outils.boit_outils)}
                        />
                        {hasAdminRole() &&
                          <MainMenu
                            isActive={this.state.active_menu.startsWith('admin')}
                            label={'Admin'}
                            items={[
                              {
                                label: <Text size="small" color={'#707070'}>Commissions TW</Text>,
                                onClick: () => this.to(MENU.admin.commissions_tw),
                              },
                              {
                                label: <Text size="small" color={'#707070'}>Gest. commerciaux</Text>,
                                onClick: () => this.to(MENU.admin.gest_commerciaux),
                              },
                              {
                                label: <Text size="small" color={'#707070'}>Gest. stats</Text>,
                                onClick: () => this.to(MENU.admin.gest_stats),
                              },
                              {
                                label: <Text size="small" color={'#707070'}>Gest. Opportunity Test</Text>,
                                onClick: () => this.to(MENU.admin.gest_opportunity),
                              },
                            ]}
                          />
                        }
                        {hasComptableRole() &&
                          <MainMenu
                            isActive={this.state.active_menu.startsWith('admin')}
                            label={'Admin'}
                            items={[
                              {
                                label: <Text size="small" color={'#707070'}>Commissions TW</Text>,
                                onClick: () => this.to(MENU.admin.commissions_tw),
                              }
                            ]}
                          />
                        }
                        {hasAdminRole() &&
                          <MainMenu
                            isActive={this.state.active_menu.startsWith('my_trustiway')}
                            label={'My Trustiway'}
                            onClick={() => this.to(MENU.my_trustiway.dashboard)}
                          />

                        }
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {this.getSubMenu()}
              </Box>

              <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </>
          )}
        </ResponsiveContext.Consumer>

      </Layout>
    );
  }
}

const EmptyContent = ({ label }) => {
  return (
    <Box width={'full'} style={{ height: 300 }} justify='center' align="center">
      <Text color={'#707070'}>{label}</Text>
    </Box>
  )
}


export default Home;
